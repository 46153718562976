$primary-color: #3bd671;

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #131a26;
}

a {
  text-decoration: none;
  color: inherit;
}

#app {
  .container {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

#header {
  background-color: #121a26;
  padding: 80px 0 110px 0;
  color: $primary-color;
  width: 100%;
  .container {
    //display: flex;
    //align-items: baseline;
    //justify-content: space-between;
  }
  .logo {
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    margin: auto;
    text-align: center;
  }
  .subtitle {
    display: block;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    opacity: .6;
  }
  .navi {
    font-size: 14px;
    color: #ffffff;
    margin: auto 30px 20px 0;
    text-align: right;
    a {
      margin-left: 20px;
      transition: color ease 150ms;
    }
    a:hover {
      color: $primary-color;
    }
  }
}

.topbar {
  margin: 25px -30px 20px;
  height: 3px;
  opacity: .6;

  .bar-item {
    width: 25%;
    height: 3px;
    display: inline-block;
  }

  .bar1 {
    background-color: #4487f6;
    filter: invert(1) hue-rotate(180deg);
    -webkit-filter: invert(1) hue-rotate(180deg);
  }
  .bar2 {
    background-color: #219540;
    filter: invert(1) hue-rotate(180deg);
    -webkit-filter: invert(1) hue-rotate(180deg);
  }
  .bar3 {
    background-color: #8a4a00;
    filter: invert(1) hue-rotate(180deg);
    -webkit-filter: invert(1) hue-rotate(180deg);
  }
  .bar4 {
    background-color: #ff7769;
    filter: invert(1) hue-rotate(180deg);
    -webkit-filter: invert(1) hue-rotate(180deg);
  }
}

.status-icon {
  margin-right: 4px;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  -ms-transform: none;
  transform: none;
  &.is-big {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    &::after {
      animation: pulseBig 2s infinite;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: currentColor;
    animation: pulse 2s infinite;
    opacity: 1;
    border-radius: 50%;
    top: 0;
    left: 0
  }

  &.ok {
    color: $primary-color;
    background: $primary-color;
  }
  &.fail, &.down {
    color: #df484a;
    background: #df484a;
  }
  &.warning {
  color: #f29030;
  background: #f29030
}
  &.unknow {
    color: #637189;
    background: #637189;
  }
}

@keyframes pulseBig {
  0% {
    opacity: 0.5
  }

  70% {
    opacity: 0;
    transform: scale(1.6)
  }

  100% {
    opacity: 0
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5
  }

  70% {
    opacity: 0;
    transform: scale(2.5)
  }

  100% {
    opacity: 0
  }
}

#overall {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-top: -30px;
  margin-bottom: 40px;
  padding: 35px 40px;
  overflow: hidden;
  line-height: 1.33;
  .flex-parent {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .flex-left {
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .text-item {
        margin: 0;
        line-height: 1.096;
        .text-status {}

        .text-status.ok {
          color: $primary-color;
        }
        .text-status.warn {
          color: #f29030;
        }
        .text-status.unknow {
          color: #637189;
        }
      }
    }
    .flex-right {
      color: #687790 !important;
      margin-top: 10px;
    }
  }
}

#uptime {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  padding: 15px 0;
  overflow: hidden;
  &.loading {
    margin-top: -30px;
    min-height: 360px;
    padding: 0;
    .site {
      padding: 0;
      margin: 160px 30px;
    }
  }
  .site {
    //border-bottom: 1px solid #e6e7e8;
    margin: 0 30px;
    //padding: 25px 0;
    padding: 25px 0 0;
    .loading {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='%23D6D8D8' d='M19.52 42.712c9.897 2.916 20.285-2.743 23.201-12.64l-3.902-1.15c-2.281 7.742-10.407 12.17-18.15 9.888l-1.15 3.902z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: block;
      margin: 0 auto;
      width: 40px;
      height: 40px;
    }
    .meta {
      display: flex;
      align-items: baseline;
      .name {
        font-size: 16px;
      }
      .link-icon {
        background-image: url("data:image/svg+xml,%3Csvg class='icon' viewBox='0 0 1036 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M600.818 697.6c-70.4 0-134.4-25.6-192-76.8-25.6-25.6-25.6-64-6.4-89.6 25.6-25.6 64-25.6 89.6-6.4 57.6 51.2 147.2 51.2 198.4 0l166.4-166.4c25.6-25.6 38.4-64 38.4-102.4 0-25.6-6.4-64-38.4-96-57.6-51.2-147.2-51.2-198.4 0l-64 76.8c-25.6 25.6-64 25.6-89.6 0-25.6-25.6-25.6-64 0-89.6l70.4-70.4c102.4-102.4 268.8-102.4 377.6 0 51.2 51.2 83.2 115.2 83.2 192 0 70.4-25.6 134.4-76.8 192l-166.4 166.4c-57.6 44.8-121.6 70.4-192 70.4z' fill='%238492A6'/%3E%3Cpath d='M274.418 1024c-70.4 0-134.4-25.6-192-76.8-108.8-96-108.8-262.4-6.4-377.6l166.4-166.4c108.8-102.4 275.2-102.4 377.6 0 25.6 25.6 25.6 64 0 89.6s-64 25.6-89.6 0c-51.2-51.2-140.8-51.2-198.4 0l-166.4 166.4c-44.8 51.2-64 140.8 0 198.4 57.6 51.2 147.2 51.2 198.4 0l70.4-70.4c25.6-25.6 64-25.6 89.6 0s25.6 64 0 89.6l-70.4 70.4c-44.8 51.2-108.8 76.8-179.2 76.8z' fill='%238492A6'/%3E%3C/svg%3E");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;
        text-indent: -99999px;
        opacity: 0.6;
        transition: opacity ease 150ms;
        display: inline-block;
        margin-right: 7px;
      }
      .link {
        font-size: 16px;
        text-decoration: underline;
        opacity: 0.75;
        //background-image: url("data:image/svg+xml,%3Csvg class='icon' viewBox='0 0 1036 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M600.818 697.6c-70.4 0-134.4-25.6-192-76.8-25.6-25.6-25.6-64-6.4-89.6 25.6-25.6 64-25.6 89.6-6.4 57.6 51.2 147.2 51.2 198.4 0l166.4-166.4c25.6-25.6 38.4-64 38.4-102.4 0-25.6-6.4-64-38.4-96-57.6-51.2-147.2-51.2-198.4 0l-64 76.8c-25.6 25.6-64 25.6-89.6 0-25.6-25.6-25.6-64 0-89.6l70.4-70.4c102.4-102.4 268.8-102.4 377.6 0 51.2 51.2 83.2 115.2 83.2 192 0 70.4-25.6 134.4-76.8 192l-166.4 166.4c-57.6 44.8-121.6 70.4-192 70.4z' fill='%238492A6'/%3E%3Cpath d='M274.418 1024c-70.4 0-134.4-25.6-192-76.8-108.8-96-108.8-262.4-6.4-377.6l166.4-166.4c108.8-102.4 275.2-102.4 377.6 0 25.6 25.6 25.6 64 0 89.6s-64 25.6-89.6 0c-51.2-51.2-140.8-51.2-198.4 0l-166.4 166.4c-44.8 51.2-64 140.8 0 198.4 57.6 51.2 147.2 51.2 198.4 0l70.4-70.4c25.6-25.6 64-25.6 89.6 0s25.6 64 0 89.6l-70.4 70.4c-44.8 51.2-108.8 76.8-179.2 76.8z' fill='%238492A6'/%3E%3C/svg%3E");
        //background-size: 100% 100%;
        //background-repeat: no-repeat;
        //width: 13px;
        //height: 13px;
        //text-indent: -99999px;
        //margin-left: 8px;
        //opacity: 0.6;
        transition: opacity ease 150ms;
        &:hover {
          opacity: 1;
          color: $primary-color;
        }
      }
      .status {
        margin-left: auto;
        color: $primary-color;
      }
    }
    .timeline {
      display: flex;
      justify-content: space-between;
      margin: 20px auto;

      &::before {
        display: none;
      }

      i {
        flex-grow: 1;
        margin: 0 1px;
        height: 25px;
        border-radius: 5px;
        transition: opacity ease 150ms;

        &.ok {
          background-color: $primary-color;
        }
        &.down {
          background-color: #de484a;
        }
        &.none {
          background-color: #e5e8eb;
        }
        &:hover {
          opacity: 0.6;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .summary {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      line-height: 15px;
      color: #9aa2af;

      span {
        min-width: calc(100% / 3);

        &:first-child {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
    .tooltip {
      padding: 6px 9px !important;
      border-radius: 5px !important;
    }
  }
  .site:last-child {
    border-bottom: none;
    margin-bottom: 20px;
  }
}

.__react_component_tooltip.place-top::before {
  display: none !important;
}

#footer {
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  color: #969ea8;
  margin: auto auto 80px;
  a {
    font-weight: bold;
    color: $primary-color;
  }

  .other-link {
    margin-right: 20px;
  }
}

@media (prefers-reduced-motion) {
  .status-icon:after {
    animation: none !important
  }

  .status-icon.is-big:after {
    animation: none !important
  }
}

@media (min-width: 576px) {
  #app .container {
    max-width:540px
  }
}

@media (min-width: 768px) {
  #app .container {
    max-width:720px
  }
}

@media (min-width: 960px) {
  #overall .left-text {
    text-align: left !important;
    h2 {
      font-size: 2rem;
      line-height: 1.096;
    }
  }
}

@media (max-width: 960px) {
  #header {
    padding: 60px 0 90px 0;
    .logo {
      font-size: 28px;
    }
    .subtitle {
      font-size: 16px;
    }
  }

  #overall {
    .flex-left {
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
      .status-icon {
        margin-right: 0;
        width: 60px;
        height: 60px;
      }

      .text-item {
        width: 100%;
        margin-top: 15px !important;
        text-align: center;
        padding: 5px 0;
      }
    }

    .flex-right {
      margin-top: 15px !important;
    }
  }

  #uptime {
    .site {
      .summary {
        span {
          min-width: auto;
        }
      }

      .timeline {
        i {
          width: 3px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #app .container {
    max-width:960px
  }
}

@media (min-width: 1200px) {
  #app .container {
    max-width:1140px
  }
}

@media (min-width: 1400px) {
  #app .container {
    max-width:1320px
  }
}
